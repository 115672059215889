import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProtectionComponent } from './protection/protection.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    ProtectionComponent
  ],
  declarations: [ProtectionComponent]
})
export class ProtectionModule { }
