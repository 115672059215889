import { Component, OnInit } from '@angular/core';
import { DashboardComponent } from '../pages/dashboard/dashboard/dashboard.component'
@Component({
  selector: 'app-pages',
  // templateUrl: './pages.component.html',
  template: `
  <app-header></app-header>
  <app-dashboard></app-dashboard>
  <app-footer></app-footer>
`,
// template: `
// <h1>hi</h1>
// `,
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
