import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popular-models',
  templateUrl: './popular-models.component.html',
  styleUrls: ['./popular-models.component.scss']
})
export class PopularModelsComponent implements OnInit {

  constructor() { }

  public carRecords: any = [
    {
      Name: 'Lexus C',
      Image: 'top1-car',
      Rank: 'top1'
    },
    {
      Name: 'Nexus Hybrid',
      Image: 'top2-car',
      Rank: 'top2'
      
    },
    {
      Name: 'Lexus RC',
      Image: 'top1-car',
      Rank: 'top3'      
    }
  ];

  ngOnInit() {
  }

}
