import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessoriesComponent } from './accessories/accessories.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports:[
    AccessoriesComponent
  ],
  declarations: [AccessoriesComponent]
})
export class AccessoriesModule { }
