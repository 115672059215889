import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent implements OnInit {

  chart = [];
  
    constructor() { }
  
    ngOnInit() {
      this.onLoad();
    }
  
    onLoad() {
  
    //   this.chart = new Chart('bar-canvas', {
    //     // config: {
    //     //   plugins: {
    //     //     coOrdinateLabel: false   // disable plugin 'coOrdinateLabel' for this instance
    //     //   }
    //     // },
    //     type: 'bar',
    //     data: {
    //       labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
    //       // datasets: [
    //       //   { 
    //       //     data: temp_max,
    //       //     borderColor: "#3cba9f",
    //       //     fill: false
    //       //   },
    //       // { 
    //       //   data: temp_min,
    //       //   borderColor: "#ffcc00",
    //       //   fill: false
    //       // },
    //       // ]
  
    //       datasets: [{
    //         data: [65, 59, 80, 81, 56, 27, 70, 48, 28, 56, 55, 40],
    //         label: 'Series A',
    //         backgroundColor: "#6ed952",
    //       }, {
    //         data: [28, 48, 40, 19, 86, 27, 90, 56, 27, 70, 48, 28],
    //         label: 'Series B',
    //         backgroundColor: "#3fa7de",
    //       },
    //       {
    //         data: [48, 28, 30, 19, 56, 27, 70, 48, 28, 30, 19, 25],
    //         label: 'Series B',
    //         backgroundColor: "#efb71e",
    //       }],
    //     },
    //     options: {
    //       plugins: {
    //         p1: false   // disable plugin 'p1' for this instance
    //     },
    //       legend: {
    //         position: 'top',
    //         labels: {
  
    //         },
    //       },
    //       scales: {
    //         xAxes: [
    //           {
    //             gridLines: {
    //               display: false,
    //             },
    //             display: true
    //           }],
    //         yAxes: [{
    //           display: true
    //         }],
    //       }
    //     }
    //   });
    // }
  }
  
  
  
    lineChart
    public lineChartData: Array<any> = [
      {
        data: [50, 80, 107, 160, 120, 198, 165, 190, 170, 140, 200, 50], label: 'Finance',
  
        backgroundColor: "#6ed952",
        fill: false,
  
      },      {
        data: [28, 48, 40, 19, 86, 27, 90, 56, 27, 70, 48, 28], label: 'Lease',
  
        backgroundColor: "#3fa7de",
        fill: false,
  
      },      {
        data: [48, 28, 30, 19, 56, 27, 70, 48, 28, 30, 19, 25], label: 'Cash',
  
        backgroundColor: "#efb71e",
        fill: false,
  
      },
    ];
  
    public lineChartLabels: Array<any> = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    public lineChartOptions: any = {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        p1: false   // disable plugin 'p1' for this instance
      },
      layout: {
        padding: {
          left: 10,
          right: 10,
          top: 15,
          bottom: 10
        }
      },
  
      tooltips: {
        mode: 'x',
        enabled:false
      },
      elements: {
        point: { radius: 4 },
  
        line: {
          tension: 0, // disables bezier curves
        },
      barThickness:0,
  
      },
      legend: {
        display: false,
        labels: {
          boxWidth: 15,
          boxHeight: 0,
          fontSize: 18,
          fontStyle: 'Bold',
          padding: 5
      }
      },
  
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              beginAtZero: true
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
            },
            ticks: {
              beginAtZero: false
            }
          },
        ],
      }
    };
    public lineChartColors: Array<any> = [
      // { // grey
      //   backgroundColor: 'rgba(148,159,177,0.2)',
      //   borderColor: 'rgba(148,159,177,1)',
      //   pointBackgroundColor: 'rgba(148,159,177,1)',
      //   pointBorderColor: '#fff',
      //   pointHoverBackgroundColor: '#fff',
      //   pointHoverBorderColor: 'rgba(148,159,177,0.8)'
      // },
      { borderColor: "#6ada51", }
  
  
    ];
    public lineChartLegend: boolean = true;
    public lineChartType: string = 'bar';
  
}
