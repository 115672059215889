import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderBlockImageComponent } from './header-block-image/header-block-image.component';
import { AccessoriesModule } from 'src/app/pages/dashboard/accessories/accessories.module';
import { CustomerVisitModule } from 'src/app/pages/dashboard/customer-visit/customer-visit.module';
import { FunnelChartModule } from 'src/app/pages/dashboard/funnel-chart/funnel-chart.module';
import { PaymentDetailsModule } from 'src/app/pages/dashboard/payment-details/payment-details.module';
import { PopularModelsModule } from 'src/app/pages/dashboard/popular-models/popular-models.module';
import { ProtectionModule } from 'src/app/pages/dashboard/protection/protection.module';
import { TimeSpendModule } from 'src/app/pages/dashboard/time-spend/time-spend.module';
import { TradeInModule } from 'src/app/pages/dashboard/trade-in/trade-in.module';
import { RebatesModule } from 'src/app/pages/dashboard/rebates/rebates.module';

@NgModule({
  imports: [
    CommonModule,
    AccessoriesModule,
    CustomerVisitModule,
    FunnelChartModule,
    PaymentDetailsModule,
    PopularModelsModule,
    ProtectionModule,
    TimeSpendModule,
    TradeInModule,
    RebatesModule
  ],
  exports: [
    DashboardComponent
  ],
  declarations: [DashboardComponent, HeaderBlockImageComponent]
})
export class DashboardModule { }
