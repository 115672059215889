import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RebatesComponent } from 'src/app/pages/dashboard/rebates/rebates/rebates.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    RebatesComponent
  ],
  declarations: [RebatesComponent]
})
export class RebatesModule { }
