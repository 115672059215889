import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public temp: any;
  public user: any;
  

  constructor() { }

  ngOnInit() {
    this.temp={
      user1:{
        'name': 'user1',
        'customer-wallpaper':1,
        'customer-order':1,
        'funnel-chart':2,
        'vehicle':3,
        'customer-details':4,
        'payment-details':5  ,
        'accessories':6,   
        'protection':7,
        'rebates':8,
        'trade-in':9   
      },
      user2:{
        'name': 'user2',        
        'customer-order':2,
        'funnel-chart':1,
        'vehicle':5,
        'customer-details':4,
        'payment-details':3        
      },
      user3:{
        'name': 'user3',        
        'customer-order':3,
        'funnel-chart':5,
        'vehicle':2,
        'customer-details':4,
        'payment-details':1
      },

    }

    this.user = this.temp.user1;
  }

}
