import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-funnel-chart',
  templateUrl: './funnel-chart.component.html',
  styleUrls: ['./funnel-chart.component.scss']
})
export class FunnelChartComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


  public lineChartData: Array<any> = [
    {
      data: [{
        x: 0,
        y: 20
      },
      {
        x: 10,
        y: 15
      },
      {
        x: 20,
        y: 11
      },
      {
        x: 30,
        y: 8
      },
      {
        x: 40,
        y: 6
      },
      {
        x: 50,
        y: 5
      }
      ], label: 'Series A',

      borderColor: "#5b5c5d",
      fill: false,
      type: 'line'

    },
    {
      data: [{
        x: 0,
        y: -20
      },
      {
        x: 10,
        y: -15
      },
      {
        x: 20,
        y: -11
      },
      {
        x: 30,
        y: -8
      },
      {
        x: 40,
        y: -6
      },
      {
        x: 50,
        y: -5
      }
      ], label: 'Series B',
      // xAxisID:{
      //   display: false
      // },
      // xAxisID: '',
      borderColor: "#5b5c5d",
      fill: false,
      type: 'line'
    },
    {
      data: [{
        x: 3,
        y: 10,
        r: 5,
      },
      {
        x: 13,
        y: 10,
        r: 7,
      },
      {
        x: 23,
        y: 5,
        r: 3,
      },
      {
        x: 33,
        y: 4,
        r: 5,
      },
      {
        x: 42,
        y: 2.5,
        r: 2,
      },
      ],
      label: {
        'Zip Code': 1221,
        'value': 5656656
      },

      borderColor: "#ea4d4f",
      backgroundColor: '#ea4d4f', // red
      fill: true,
      type: 'bubble'

    },
    {
      data: [{
        x: 3,
        y: 0,
        r: 5,
      },
      {
        x: 13,
        y: 0,
        r: 5,
      },
      {
        x: 23,
        y: 0,
        r: 5,
      },
      {
        x: 33,
        y: 0,
        r: 5,
      },
      {
        x: 45,
        y: 2.5,
        r: 5,
      },
      
      ],
      label: {
        'Zip Code': 1221,
        'value': 5656656
      },

      borderColor: "#00b6e8",
      backgroundColor: '#00b6e8',  // blue
      fill: true,
      type: 'bubble'

    },

    {
      data: [{
        x: 3,
        y: -10,
        r: 5,
      },
      {
        x: 13,
        y: -10,
        r: 5,
      },
      {
        x: 23,
        y: -5,
        r: 5,
      },
      {
        x: 33,
        y: -5,
        r: 5,
      },
      {
        x: 48,
        y: 2.5,
        r: 5,
      },

      ],
      label: {
        'Zip Code': 1221,
        'value': 5656656
      },

      borderColor: "#ffba13",
      backgroundColor: '#ffba13',  // orange
      fill: true,
      type: 'bubble'

    },

    {
      data: [{
        x: 7,
        y: 5,
        r: 5,
      },
      {
        x: 17,
        y: 5,
        r: 5,
      },
      {
        x: 27,
        y: 2.5,
        r: 5,
      },
      {
        x: 37,
        y: 2.5,
        r: 5,
      },
      {
        x: 43.5,
        y: -2.5,
        r: 5,
      },
      ],
      label: {
        'Zip Code': 1221,
        'value': 5656656
      },

      borderColor: "#499816",
      backgroundColor: '#499816',  // green
      fill: true,
      type: 'bubble'

    },

    {
      data: [{
        x: 7,
        y: -5,
        r: 5,
      },
      {
        x: 17,
        y: -5,
        r: 5,
      },
      {
        x: 27,
        y: -2.5,
        r: 5,
      },
      {
        x: 37,
        y: -2.5,
        r: 5,
      },
      {
        x: 47.5,
        y: -2.5,
        r: 5,
      }
      ],
      label: {
        'Zip Code': 1221,
        'value': 5656656
      },

      borderColor: "#aa1ace",
      backgroundColor: '#aa1ace',  // violet
      fill: true,
      type: 'bubble'

    },

// ---------------------------------------------------------------------------------------//

    {
      data: [{
        x: 10,
        y: -15
      },
      {
        x: 10,
        y: 15
      }
      ],
      label: {
        'Zip Code': 1221,
        'value': 5656656
      },

      borderColor: "#5b5c5d",
      backgroundColor: '#5b5c5d',
      fill: true,
      type: 'line'

    },
    {
      data: [{
        x: 20,
        y: -11
      },
      {
        x: 20,
        y: 11
      }
      ],
      label: {
        'Zip Code': 1221,
        'value': 5656656
      },

      borderColor: "#5b5c5d",
      backgroundColor: '#5b5c5d', 
      fill: true,
      type: 'line',
      steppedLine:true

    },
    {
      data: [{
        x: 30,
        y: 8
      },
      {
        x: 30,
        y: -8
      }
      ],
      label: {
        'Zip Code': 1221,
        'value': 5656656
      },

      borderColor: "#5b5c5d",
      backgroundColor: '#5b5c5d',  
      fill: true,
      type: 'line',
      steppedLine:true

    },
    {
      data: [{
        x: 40,
        y: 6
      },
      {
        x: 40,
        y: -6
      }
      ],
      label: {
        'Zip Code': 1221,
        'value': 5656656
      },

      borderColor: "#5b5c5d",
      backgroundColor: '#5b5c5d', 
      fill: true,
      type: 'line',
      steppedLine:true

    },
    {
      data: [{
        x: 50,
        y: -5
      },
      {
        x: 50,
        y: 5
      }
      ],
      label: {
        'Zip Code': 1221,
        'value': 5656656
      },

      borderColor: "#5b5c5d",
      backgroundColor: '#5b5c5d', 
      fill: true,
      type: 'line',
      steppedLine:true

    }

// ---------------------------------------------------------------------------------------//
    

  ];
  public lineChartLabels: Array<any> = [0, 100, 200, 300, 400, 500];
  // public lineChartLabels: Array<any> = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  public lineChartOptions: any = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      p1: false   // disable plugin 'p1' for this instance
    },
    // devicePixelRatio: window.devicePixelRatio,

    layout: {
      padding: {
        left: -30,
        // right: 10,
        top: 15,
        bottom: 10
      }
    },
    // tooltips: {
    //   mode: 'x'
    // },
    elements: {
      point: { radius: 3 },

      line: {
        tension: 0, // disables bezier curves
      }
    },
    legend: {
      display: false
    },

    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            // display: true,
            display: false,
            drawBorder: false
          },
          ticks: {
            beginAtZero: false
          }
        },
      ],
    }
  };
  public lineChartColors: Array<any> = [
    // { // grey
    //   backgroundColor: 'rgba(148,159,177,0.2)',
    //   borderColor: 'rgba(148,159,177,1)',
    //   pointBackgroundColor: 'rgba(148,159,177,1)',
    //   pointBorderColor: '#fff',
    //   pointHoverBackgroundColor: '#fff',
    //   pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    // },
    // { borderColor: "#6ada51", }


  ];
  public lineChartLegend: boolean = true;
  public lineChartType: string = 'bubble';

  public randomize(): void {
    let _lineChartData: Array<any> = new Array(this.lineChartData.length);
    for (let i = 0; i < this.lineChartData.length; i++) {
      _lineChartData[i] = { data: new Array(this.lineChartData[i].data.length), label: this.lineChartData[i].label };
      for (let j = 0; j < this.lineChartData[i].data.length; j++) {
        _lineChartData[i].data[j] = Math.floor((Math.random() * 150) + 1);
      }
    }
    this.lineChartData = _lineChartData;
  }

}
