import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FunnelChartComponent } from './funnel-chart/funnel-chart.component';
import { ChartsModule } from 'ng2-charts';

@NgModule({
  imports: [
    CommonModule,
    ChartsModule
  ],
  exports: [
    FunnelChartComponent
  ],
  declarations: [FunnelChartComponent]
})
export class FunnelChartModule { }
