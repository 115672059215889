import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { ChartsModule } from 'ng2-charts';

@NgModule({
  imports: [
    CommonModule,
    ChartsModule
  ],
  exports: [
    PaymentDetailsComponent
  ],
  declarations: [PaymentDetailsComponent]
})
export class PaymentDetailsModule { }
