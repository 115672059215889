import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopularModelsComponent } from './popular-models/popular-models.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    PopularModelsComponent
  ],
  declarations: [PopularModelsComponent]
})
export class PopularModelsModule { }
