import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesComponent } from './pages.component';
import { PagesRoutingModule } from './pages-routing.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DashboardComponent } from 'src/app/pages/dashboard/dashboard/dashboard.component';
import { DashboardModule } from 'src/app/pages/dashboard/dashboard.module';

@NgModule({
  imports: [
    CommonModule,
    PagesRoutingModule,
    DashboardModule
  ],
  declarations: [PagesComponent, HeaderComponent, FooterComponent]
})
export class PagesModule { }
