import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js'
import { DateRangePickerComponent } from '../../../../shared/date-range-picker/date-range-picker/date-range-picker.component'

@Component({
  selector: 'app-customer-visit',
  templateUrl: './customer-visit.component.html',
  styleUrls: ['./customer-visit.component.scss']
})
export class CustomerVisitComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


  // lineChart
  public lineChartData: Array<any> = [
    {
      data: [50, 80, 107, 160, 120, 198, 165, 190, 170, 140, 250, 50], label: 'Series A',

      borderColor: "#6ada51",
      fill: false,

    },
  ];

  public lineChartLabels: Array<any> = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  public lineChartOptions: any = {
    responsive: true,
    maintainAspectRatio: true,
    // devicePixelRatio: window.devicePixelRatio,

    layout: {
      padding: {
        left: 0,
        right: -10,
        top: 5,
        bottom: 5
      }
    },
    // tooltips: {
    //   mode: 'x'
    // },
    elements: {
      point: { radius: 3 },

      line: {
        tension: 0, // disables bezier curves
      }
    },
    legend: {
      display: false
    },

    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
            drawOnChartArea: true,
            drawTicks: false,
          },
          ticks: {
            beginAtZero: true
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
            drawBorder: false,
            // drawOnChartArea: true,
            // drawTicks: false,
          },
          ticks: {
            beginAtZero: false
          }
        },
      ],
    }
  };
  public lineChartColors: Array<any> = [
    // { // grey
    //   backgroundColor: 'rgba(148,159,177,0.2)',
    //   borderColor: 'rgba(148,159,177,1)',
    //   pointBackgroundColor: 'rgba(148,159,177,1)',
    //   pointBorderColor: '#fff',
    //   pointHoverBackgroundColor: '#fff',
    //   pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    // },
    { borderColor: "#6ada51",
      pointBackgroundColor: '#fff'
   }


  ];
  public lineChartLegend: boolean = true;
  public lineChartType: string = 'line';

  public randomize(): void {
    let _lineChartData: Array<any> = new Array(this.lineChartData.length);
    for (let i = 0; i < this.lineChartData.length; i++) {
      _lineChartData[i] = { data: new Array(this.lineChartData[i].data.length), label: this.lineChartData[i].label };
      for (let j = 0; j < this.lineChartData[i].data.length; j++) {
        _lineChartData[i].data[j] = Math.floor((Math.random() * 150) + 1);
      }
    }
    this.lineChartData = _lineChartData;
  }

}
