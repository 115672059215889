import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TradeInComponent } from './trade-in/trade-in.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    TradeInComponent
  ],
  declarations: [TradeInComponent]
})
export class TradeInModule { }
