import { Component , OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'app';
  public userPin = 2;  
  theme: string; 
   constructor() {
  
   }
  ngOnInit(){
    this.themeSelection();

  }

  public themeSelection(){
    // this.theme = this.userPin  === 1 ? '/assets/black-theme.css' : '/assets/blue-theme.css';
    if(this.userPin === 1){
      // this.theme = 'black-theme'
      document.getElementById('theme').setAttribute('href','assets/black-theme.css');
      
    }else{
      // this.theme = 'blue-theme'
    document.getElementById('theme').setAttribute('href','assets/blue-theme.css');
    }
  }

}
