import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerVisitComponent } from './customer-visit/customer-visit.component';
import { ChartsModule } from 'ng2-charts';
import { SharedModule } from 'src/app/shared/shared.module';
import { DateRangePickerModule } from 'src/app/shared/date-range-picker/date-range-picker.module';

@NgModule({
  imports: [
    CommonModule,
    ChartsModule,
    // SharedModule,
    DateRangePickerModule
  ],
  exports: [
    CustomerVisitComponent
  ],
  declarations: [CustomerVisitComponent]
})
export class CustomerVisitModule { }
